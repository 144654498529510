@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  --tags-adjust: 5rem;
  --bg-adjust: 24rem;
}

menu {
  top: 38rem;

  overflow: hidden;

  .fa-bars {
    display: none;
  }
}

menu ol {
  gap: 2rem;

  ul:hover {
    cursor: pointer;
  }

  ul:hover:not(.active) {
    font-weight: bolder;
  }
  ul.active {
    text-decoration: underline;
  }
}

#career-list, #project-list {
  width: 75%;
}

#career-list {
  @apply mt-4 mx-auto;

  .career-item {
    @apply p-4 min-w-96;

    &:hover {
      @apply font-bold;
    }

    > div, > header {
      @apply cursor-pointer;
    }

    > div:first-of-type {
      @apply bg-white rounded-2xl h-6 w-6 border-slate-600 border-2 m-1;
    }
  }

  header {
    @apply ml-10;

    h3 {
      @apply text-xl;
    }

    h4 {
      @apply pl-2 text-neutral-600;
    }
  }
}

#project-list {
  a:hover {
    @apply font-bold;
  }
}

#tags {
  @apply absolute text-white z-10;

  left: 20%; 
  width: 70%;

  top: calc(27rem + var(--tags-adjust));
}

#tags span {
  @apply rounded-lg mb-2 bg-amber-800 whitespace-nowrap;

  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  line-height: 2rem;
  font-size: 11pt;
  cursor: default;
}

#links {
  @apply absolute top-0 right-0 px-8 py-4 flex gap-3;

  img {
    width: 3rem;
  }
}

@media only screen and (max-width: 1600px) {
  body {
    --tags-adjust: 4rem;
  }
}

@media only screen and (max-width: 1500px) {
  body {
    --tags-adjust: 3rem;
  }
}

@media only screen and (max-width: 1400px) {
  body {
    --tags-adjust: 2rem;
  }
}

@media only screen and (max-width: 1300px) {
  body {
    --tags-adjust: 1rem;
  }
}


@media only screen and (max-width: 500px) {
  body {
    --tags-adjust:-1.25rem;
  }
  
  #career-list, #project-list {
    width: 100%;
  }
}

@media only screen and (max-width: 700px) {
  #about-1 {
    display: none;
  }

  #links {
    gap: 0.33rem;
  }
  #links img {
    width: 2rem;
  }
}

@media only screen and (max-width: 950px) {
  body {
    --bg-adjust: 4rem;
  }

  #about-2 {
    display: none;
  }

  #tags span {
    line-height: 2rem;
    font-size: 11pt;
  }
}

@media only screen and (max-width: 1024px) {
  #nav.active {
    ul {
      display: block;
    }
  }

  menu ol {
    flex-direction: column;
    gap: 0.5rem;
  }

  menu .fa-bars {
    display: block;
    float: left;
    padding:.75rem;
    cursor: pointer;

  }

  #tags {
    overflow-x: scroll;
    max-height: 12rem;
  }
}

.rv-root {
  z-index: 99;
}

.modal {
  display: none;
}

.modal.is-open {
  display: block;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  background-color: #fff;
  padding: 30px;
  max-width: 500px;
  max-height: 100vh;
  border-radius: 4px;
  overflow-y: auto;
  box-sizing: border-box;

  h4 {
    @apply font-bold text-lg;
  }

  h5 {
    @apply mb-2;
  }

  ul {
    @apply text-sm list-disc mb-3;

    li {
      @apply mb-1;
    }
  }

  a {
    @apply text-blue-600;
  }

  a:visited {
    @apply text-purple-500;
  }
}
