*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.right-4 {
  right: 1rem;
}

.top-0 {
  top: 0;
}

.-z-30 {
  z-index: -30;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.m-1 {
  margin: .25rem;
}

.m-auto {
  margin: auto;
}

.mx-24 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-28 {
  margin-left: 7rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-48 {
  margin-top: 12rem;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-20 {
  height: 5rem;
}

.h-96 {
  height: 24rem;
}

.w-20 {
  width: 5rem;
}

.w-3\/4 {
  width: 75%;
}

.w-dvw {
  width: 100dvw;
}

.w-full {
  width: 100%;
}

.min-w-96 {
  min-width: 24rem;
}

.flex-none {
  flex: none;
}

.flex-grow {
  flex-grow: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-col {
  flex-direction: column;
}

.gap-1 {
  gap: .25rem;
}

.gap-7 {
  gap: 1.75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.border-2 {
  border-width: 2px;
}

.border-slate-600 {
  --tw-border-opacity: 1;
  border-color: rgb(71 85 105 / var(--tw-border-opacity, 1));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.p-4 {
  padding: 1rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pt-2 {
  padding-top: .5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity, 1));
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity, 1));
}

.underline {
  text-decoration-line: underline;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

body {
  --tags-adjust: 5rem;
  --bg-adjust: 24rem;
}

menu {
  top: 38rem;
  overflow: hidden;

  & .fa-bars {
    display: none;
  }
}

menu ol {
  gap: 2rem;

  & ul:hover {
    cursor: pointer;
  }

  & ul:hover:not(.active) {
    font-weight: bolder;
  }

  & ul.active {
    text-decoration: underline;
  }
}

#career-list, #project-list {
  width: 75%;
}

#career-list {
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;

  & .career-item {
    min-width: 24rem;
    padding: 1rem;
  }

  & .career-item {
    &:hover {
      font-weight: 700;
    }

    & > div, & > header {
      cursor: pointer;
    }

    & > div:first-of-type {
      --tw-border-opacity: 1;
      border-width: 2px;
      border-color: rgb(71 85 105 / var(--tw-border-opacity, 1));
      --tw-bg-opacity: 1;
      background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
      border-radius: 1rem;
      width: 1.5rem;
      height: 1.5rem;
      margin: .25rem;
    }
  }

  & header {
    margin-left: 2.5rem;
  }

  & header {
    & h3 {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    & h4 {
      --tw-text-opacity: 1;
      color: rgb(82 82 82 / var(--tw-text-opacity, 1));
      padding-left: .5rem;
    }
  }
}

#project-list {
  & a:hover {
    font-weight: 700;
  }
}

#tags {
  z-index: 10;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  width: 70%;
  left: 20%;
  top: calc(27rem + var(--tags-adjust));
  position: absolute;
}

#tags span {
  white-space: nowrap;
  --tw-bg-opacity: 1;
  background-color: rgb(146 64 14 / var(--tw-bg-opacity, 1));
  cursor: default;
  border-radius: 1rem;
  margin-bottom: .5rem;
  padding: .25rem .75rem;
  font-size: 11pt;
  line-height: 2rem;
}

#links {
  gap: .75rem;
  padding: 1rem 2rem;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;

  & img {
    width: 3rem;
  }
}

@media only screen and (width <= 1600px) {
  body {
    --tags-adjust: 4rem;
  }
}

@media only screen and (width <= 1500px) {
  body {
    --tags-adjust: 3rem;
  }
}

@media only screen and (width <= 1400px) {
  body {
    --tags-adjust: 2rem;
  }
}

@media only screen and (width <= 1300px) {
  body {
    --tags-adjust: 1rem;
  }
}

@media only screen and (width <= 500px) {
  body {
    --tags-adjust: -1.25rem;
  }

  #career-list, #project-list {
    width: 100%;
  }
}

@media only screen and (width <= 700px) {
  #about-1 {
    display: none;
  }

  #links {
    gap: .33rem;
  }

  #links img {
    width: 2rem;
  }
}

@media only screen and (width <= 950px) {
  body {
    --bg-adjust: 4rem;
  }

  #about-2 {
    display: none;
  }

  #tags span {
    font-size: 11pt;
    line-height: 2rem;
  }
}

@media only screen and (width <= 1024px) {
  #nav.active {
    & ul {
      display: block;
    }
  }

  menu ol {
    flex-direction: column;
    gap: .5rem;
  }

  menu .fa-bars {
    float: left;
    cursor: pointer;
    padding: .75rem;
    display: block;
  }

  #tags {
    max-height: 12rem;
    overflow-x: scroll;
  }
}

.rv-root {
  z-index: 99;
}

.modal {
  display: none;
}

.modal.is-open {
  display: block;
}

.modal-overlay {
  background: #0009;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.modal-container {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  max-width: 500px;
  max-height: 100vh;
  padding: 30px;
  overflow-y: auto;

  & h4 {
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.75rem;
  }

  & h5 {
    margin-bottom: .5rem;
  }

  & ul {
    margin-bottom: .75rem;
    font-size: .875rem;
    line-height: 1.25rem;
    list-style-type: disc;
  }

  & ul {
    & li {
      margin-bottom: .25rem;
    }
  }

  & a {
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity, 1));
  }

  & a:visited {
    --tw-text-opacity: 1;
    color: rgb(168 85 247 / var(--tw-text-opacity, 1));
  }
}
/*# sourceMappingURL=career.5be2ec5d.css.map */
